import 'lazysizes';
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faWaze } from '@fortawesome/free-brands-svg-icons'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { faChevronCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faCalendarPlus } from '@fortawesome/free-regular-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { faUniversity } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { faTag } from '@fortawesome/free-solid-svg-icons'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import feather from 'feather-icons';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils.js'
import lottie from 'lottie-web/build/player/lottie_svg';
import 'jquery-validation';
import 'bootstrap/js/dist/modal';
/* import 'bootstrap/js/dist/collapse'; */
window.captchaSuccess = captchaSuccess;

library.add(faCalendar, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faQuestionCircle, faTimes, faSlidersH, faPlus, faMinus, faTag, faTags, faSearch, faClock, faInfoCircle, faUniversity, faLinkedinIn, faMapMarkedAlt, faFacebookF, faFacebook, faYoutube, faComments, faArrowRight, faCalendarPlus, faCaretRight, faChevronRight, faInstagram, faWaze, faGoogle, faChevronCircleUp, faWhatsapp, faPhone, faEnvelope, faMapMarkerAlt)
dom.watch()

feather.replace()

jQuery.validator.setDefaults({
  onkeyup: false,
  errorElement: 'div',
  errorClass: 'invalid-feedback',

  errorPlacement: function (error, element) {
    element.closest('.form-group').append(error);
  },
  highlight: function (element, errorClass, validClass) {
    $(element).removeClass('is-valid').addClass('is-invalid');
  },
  unhighlight: function (element, errorClass, validClass) {
    $(element).removeClass('is-invalid').addClass('is-valid');
    $(element).next('.invalid-feedback').remove();
  }
});

$.validator.addMethod("notEqualTo", function (value, element, param) {
  return this.optional(element) || !$.validator.methods.equalTo.call(this, value, element, param);
}, "Your last name cannot be the same as your first name.");

//Variables Init
var $blog_list = $("#blog_list");
var blog_list_pagination = 1;
var $workshop_list = $("#workshop_list");
var workshop_list_pagination = 1;

document.addEventListener("DOMContentLoaded", function () {
  var elem = document.getElementById("header-logo");
  lottie.loadAnimation({
    container: elem, // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: '/PrepWorks-logo-bm.json' // the path to the animation json
  });

  // Getting Country data from HTTP header
  var visitor_country = $("#visitor-country").data('visitor-country') || "my";
  // Initializing Phone Number
  if ($("#phone_field").length) {
    var input = document.querySelector("#phone_field");
    var user_telephone_iti = intlTelInput(input, {
      initialCountry: visitor_country,
      autoPlaceholder: "off",
      separateDialCode: true,
      customContainer: "w-100",
      preferredCountries: ["my", "sg"],
      utilsScript: "../intl-tel-input/build/js/utils.js"
    });

    if ($("#phone_field").val() != "") {
      user_telephone_iti.setNumber($("#phone_field").val());
    }

    jQuery.validator.addMethod("phone", function (value, element) {
      return this.optional(element) || user_telephone_iti.isValidNumber();
    }, "Please enter a valid phone number.");

  }
  function normalizeSlideHeights() {
    var $win = $(window);
    var winWidth = $win.width();

    if (winWidth <= 992)
      return;

    $('.carousel').each(function () {
      var items = $('.carousel-equal-height', this);
      // reset the height
      items.css('min-height', 0);
      // set the height
      var maxHeight = Math.max.apply(null,
        items.map(function () {
          return $(this).outerHeight()
        }).get());
      items.css('min-height', maxHeight + 'px');
    })
  }

  $(window).on(
    'load resize orientationchange',
    normalizeSlideHeights);

  if($("#shooting-stars").length){
    particlesJS("shooting-stars", {
      "particles": {
        "number": {
          "value": 104,
          "density": {
            "enable": true,
            "value_area": 768
          }
        },
        "color": {
          "value": "#ffffff"
        },
        "shape": {
          "type": "star"
        },
        "image": {
          "width": 100,
          "height": 100
        },
        "opacity": {
          "value": 1,
          "random": false,
          "anim": {
            "enable": true,
            "speed": 0.2,
            "opacity_min": 0,
            "sync": false
          }
        },
        "size": {
          "value": 1.5,
          "random": true,
          "anim": {
            "enable": true,
            "speed": 2,
            "size_min": 0,
            "sync": false
          }
        },
        "line_linked": {
          "enable": false
        },
        "move": {
          "enable": true,
          "speed": 4,
          "direction": "bottom-left",
          "random": false,
          "straight": true
        }
      },
      "interactivity": {
        "detect_on": "canvas",
        "events": {
          "onhover": {
            "enable": false
          },
          "onclick": {
            "enable": false
          },
          "resize": true
        }
      },
      "retina_detect": true
    }
    );
  }

  $(window).on(
    'load resize orientationchange',
    normalizeSlideHeights);

  $('.second-button').on('click', function () {
    $('.animated-icon2').toggleClass('open');
  });

  $('.scroll1').click(function () {
    var sectionTo = $(this).attr('href');
    $('html, body').animate({
      scrollTop: $(sectionTo).offset().top
    }, 500);
  });

  $('.scroll2').click(function () {
    var sectionTo = $(this).attr('href');
    $('html, body').animate({
      scrollTop: $(sectionTo).offset().top
    }, 1000);
  });

  // Services Enquiry Form
  $("#enquiryForm").validate({
    submitHandler: function (form) {
      $("#phone_field").val(user_telephone_iti.getNumber());
      $("#serviceRegisterSubmitBtn").prop("disabled", true).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Submitting...");
      $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        dataType: "json",
        success: function (response) {
          $('#registerModal').modal('hide');
          $("#serviceRegisterSubmitBtn").prop("disabled", false).html("Register");
          $('#registerCompleteModal').modal('show');
        },
        error: function (xhr) {
          var message = "<p id='recpatcha-error' class='text-danger'>" + JSON.parse( xhr.responseText)["message"] + "</p>";
          $("#recaptcha-container").append(message);
          $("#serviceRegisterSubmitBtn").prop("disabled", false).html("Send");
        }
      });
    },
    rules: {
      last_name: { notEqualTo: "#first_name" },
      email: { email: true },
      phone: { phone: true }
    }
  });

  // Contact Us Form
  $("#contactUsForm").validate({
    submitHandler: function (form) {
      $("#phone_field").val(user_telephone_iti.getNumber());
      $("#contactFormSubmitBtn").prop("disabled", true).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Submitting...");
      $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        dataType: "json",
        success: function (response) {
          $("#contactFormSubmitBtn").prop("disabled", false).html("Send");
          $('#contactUsCompleteModal').modal('show');
        },
        error: function (xhr) {
          var message = "<p id='recpatcha-error' class='text-danger'>" + JSON.parse( xhr.responseText)["message"] + "</p>";
          $("#recaptcha-container").append(message);
          $("#contactFormSubmitBtn").prop("disabled", false).html("Send");
        }
      });
    },
    rules: {
      last_name: { notEqualTo: "#contact_first_name" },
      email: { email: true },
      phone: { phone: true }
    }
  });
  // Ebook Newsletter Subscription Form
  $("#ebookNewsletterForm").validate({
    submitHandler: function (form) {
      $("#ebookNewsletterRegisterSubmitBtn").prop("disabled", true).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Submitting...");
      $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        dataType: "json",
        success: function (response) {
          $('#subscribeModal').modal('hide');
          $("#ebookNewsletterRegisterSubmitBtn").prop("disabled", false).html("Subscribe & Download e-Book");
          $('#eBookCompleteModal').modal('show');
        },
        error: function (xhr) {
          var message = "<p id='recpatcha-error' class='text-danger'>" + JSON.parse( xhr.responseText)["message"] + "</p>";
          $("#recaptcha-container-ebook").append(message);
          $("#ebookNewsletterRegisterSubmitBtn").prop("disabled", false).html("Send");
        }
      });
    },
    rules: {
      last_name: { notEqualTo: "#ebook_first_name" },
      email: { email: true }
    }
  });

  // Ivy Leagues Ebook Newsletter Subscription Form
  $("#IvyLeagueEbookNewsletterForm").validate({
    submitHandler: function (form) {
      $("#IvyLeagueEbookNewsletterRegisterSubmitBtn").prop("disabled", true).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Submitting...");
      $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        dataType: "json",
        success: function (response) {
          $('#subscribeModalIvyLeague').modal('hide');
          $("#IvyLeagueEbookNewsletterRegisterSubmitBtn").prop("disabled", false).html("Subscribe & Download e-Book");
          $('#eBookCompleteModal').modal('show');
        },
        error: function (xhr) {
          var message = "<p id='recpatcha-error' class='text-danger'>" + JSON.parse( xhr.responseText)["message"] + "</p>";
          $("#recaptcha-container-ivy-league").append(message);
          $("#IvyLeagueEbookNewsletterRegisterSubmitBtn").prop("disabled", false).html("Send");
        }
      });
    },
    rules: {
      last_name: { notEqualTo: "#ivy_ebook_first_name" },
      email: { email: true }
    }
  });

  // Newsletter Subscription Form
  $("#newsletterForm").validate({
    submitHandler: function (form) {
      $("#newsletterSubmitBtn").prop("disabled", true).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Submitting...");
      $.ajax({
        url: $(form).attr('action'),
        type: "POST",
        data: $(form).serialize(),
        dataType: "json",
        success: function (response) {
          $("#newsletterSubmitBtn").prop("disabled", false).html("Subscribe");
          $('#subscribeCompleteModal').modal('show');
        },
        error: function (xhr) {
          var message = "<p id='recpatcha-error' class='text-danger'>" + JSON.parse( xhr.responseText)["message"] + "</p>";
          $("#recaptcha-container-newsletter").append(message);
          $("#newsletterSubmitBtn").prop("disabled", false).html("Send");
        }
      });
    },
    rules: {
      last_name: { notEqualTo: "#newsletter_first_name" },
      email: { email: true }
    }
  });

  $('#registerModal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var pipeline = button.data('pipeline') // Extract service pipeline from data-pipeline
    var deal = button.data('deal') // Extract education year from data-year
    var title = button.data('title') // Extract title for Registration Form Title
    var modal = $(this)
    modal.find('.registrationPipeline').append('<input type="hidden" name="interested_services" value="' + deal + '">')
    modal.find('.registrationPipeline').append('<input type="hidden" name="pipeline" value="' + pipeline + '">')
    modal.find('.registrationPipeline').append('<input type="hidden" name="registered_subject" value="' + title + ' - ' + deal + '">')
    modal.find('.modal-title').text(title)
  });

  $('#registerModal').on('hide.bs.modal', function () {
    var modal = $(this)
    modal.find('.registrationPipeline').empty()
    modal.find('.modal-title').empty()
  });
  
  $('.collapse.collapscroll').on('show.bs.collapse', function(e) {
    var $card = $(this).closest('.card');
    var $open = $($(this).data('parent')).find('.collapse.show');
    
    var additionalOffset = 0;
    if($card.prevAll().filter($open.closest('.card')).length !== 0)
    {
          additionalOffset =  $open.height();
    }
    $('html,body').animate({
      scrollTop: $card.offset().top - additionalOffset - 100
    }, 500);
  });

  $('.clarissa-vid').click(function () {
    $(this).empty()
    $(this).append('<stream src="4da00ae6fd257cb1f9d0b1713317970f" controls autoplay></stream><script data-cfasync="false" defer type="text/javascript" src="https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=4da00ae6fd257cb1f9d0b1713317970f"></script>')
  });
  $('.sachdev-vid').click(function () {
    $(this).empty()
    $(this).append('<stream src="c64022b29008139f5eec2bf1cc5b8be7" controls autoplay></stream><script data-cfasync="false" defer type="text/javascript" src="https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=c64022b29008139f5eec2bf1cc5b8be7"></script>')
  });
  $('.academy-vid').click(function () {
    $(this).empty()
    $(this).append('<stream src="fb3f81d511231659905751baa7e1ecaa" controls autoplay></stream><script data-cfasync="false" defer type="text/javascript" src="https://embed.videodelivery.net/embed/r4xu.fla9.latest.js?video=fb3f81d511231659905751baa7e1ecaa"></script>')
  });

  //Hide Top Bar on Scroll
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function () {
    var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("main-nav").style.top = "36px";
    } else {
      document.getElementById("main-nav").style.top = "0px";
    }
    prevScrollpos = currentScrollPos;
  }

  loadWorkshops();

  //Course Filter
  var courseFilter = function() {
    var ww = document.body.clientWidth;
    if (ww < 992) {
      $('#courseFilters #filters.collapse').removeClass('show');
    } else if (ww >= 992) {
      $('#courseFilters #filters.collapse').addClass('show');
    };
  };
  $(window).resize(function(){
    courseFilter();
  });
  courseFilter();

  //Mini Course List
  $('.courseList-cta .courseList-cta-close').click(function(){
    $('.courseList-cta').addClass('d-none')
  });
});

function captchaSuccess(){
  $("#recpatcha-error").remove();
};

function loadWorkshops() {
  var xhr = $.getJSON("https://blog.prep.works/wp-json/wp/v2/posts?_embed&per_page=3&categories=22&page=" + workshop_list_pagination + "", function (data) {
    workshop_list_pagination++;

    $.each(data, function (index, value) {
      var blog_date = new Date(Date.parse(value.date));
      var blog_post_container = "<div class='col-12 col-md-4 mb-4 pb-4'></div>"
      var blog_post = $.parseHTML(blog_post_container);
      if (value._embedded.hasOwnProperty('wp:featuredmedia')) {
        $(blog_post).append("<a href=" + value.link + " class='blog-img-link'><div class='mb-3 rounded-xl bg-white blog-img shadow'><img class='w-100' src=" + value._embedded['wp:featuredmedia']['0'].source_url + "></div></a>");
      }
      $(blog_post).append("<p class='mb-1'><small>" + blog_date.toDateString() + "</small></p>");
      $(blog_post).append("<a href=" + value.link + " class='text-dark text-decoration-none blog-title'><h5 class='font-weight-bold mb-4 pb-2'>" + value.title.rendered + "</h5></a>");
      $(blog_post).append("<div class='workshop-btn'><a href=" + value.link + " class='btn btn-danger btn-pill'>Find out more</a></div>");
      $workshop_list.append(blog_post);
      $("#blog_preloader").remove();
    });
  });
}